import Amplify from 'aws-amplify';
import utils from './amplify';

export default Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID, //'ap-southeast-1:e50f8d51-9b9c-4a5a-8cf6-65db4fa7e223',
    identityPoolRegion: process.env.REACT_APP_REGION,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // 'ap-southeast-1_H9PUDARXH',
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, //'234pkbddv62krnlk01o1t5lu1i',
  },
  API: {
    endpoints: utils.endpoints,
  },
});
