// import {API} from 'aws-amplify';

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';

const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILED = 'LOGOUT_FAILED';

const initialState = {
  isAuthenticated: false,
  userData: {},
};

export default function reducers(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {...state, isAuthenticated: true, userData: payload};
    case LOGOUT_SUCCESS:
      return {...state, isAuthenticated: false, userData: {}};
    default:
      return state;
  }
}

export const loggingIn = (userdata) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({type: LOGIN_SUCCESS, payload: userdata});
      resolve();
    });
  };
};

export const loggingOut = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({type: LOGOUT_SUCCESS});
      resolve();
    });
  };
};
