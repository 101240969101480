export const USER_DATA_API = 'UsersDataAPI';
export const PARTICIPANT_CRITERION_API = 'ParticipantCriterionAPI';
export const RESEARCHER_API = 'ResearcherAPI';
export const TRANSACTION_API = 'TransactionAPI';
export const CITIES_API = 'CitiesAPI';
export const NOTIFY_EMAIL_API = 'NotifyEmailAPI';
export const CONTACT_US_API = 'ContactUsAPI';

const region = process.env.REACT_APP_REGION;
// region = 'ap-southeast-1'
const endpoints = [
  {
    name: PARTICIPANT_CRITERION_API,
    endpoint: process.env.REACT_APP_PARTICIPANT_API,
    region,
  },
  {
    name: RESEARCHER_API,
    endpoint: process.env.REACT_APP_RESEARCHER_TEMP_API,
    region,
  },
  {
    name: TRANSACTION_API,
    endpoint: process.env.REACT_APP_TRANSACTION_API,
    region,
  },
  {
    name: USER_DATA_API,
    endpoint: process.env.REACT_APP_USER_DATA_API,
    region,
  },
  {
    name: CITIES_API,
    endpoint: process.env.REACT_APP_CITIES_API,
    region,
  },
  {
    name: CONTACT_US_API,
    endpoint: process.env.REACT_APP_CONTACT_US,
    region,
  },
];

export default {
  endpoints,
};
