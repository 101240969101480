import { createStore, applyMiddleware, compose } from 'redux';

import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import reducers from './action-reducers';

let middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, createLogger()];
}
const middleware = compose(applyMiddleware(...middlewares));
const store = createStore(reducers, {}, middleware);

export default store;
