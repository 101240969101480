import axios from 'axios';
import {Auth} from 'aws-amplify';

const methods = ['get', 'post', 'put', 'patch', 'delete'];
class PopCall {
  constructor(isExternal, externalUrl) {
    this.apiclient = axios.create({
      baseURL: isExternal ? externalUrl : `${process.env.REACT_APP_BASE_URL}`, // use env
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.defineInterceptor();
    methods.forEach((method) => {
      this[method] = (targetUrl, body) => {
        return new Promise((resolve, reject) => {
          this.apiclient[method](targetUrl, body)
            .then((res) => {
              resolve(res.data); // Note, might want to adjust the resolved data
            })
            .catch((error) => reject(error));
        });
      };
    });
  }

  defineInterceptor() {
    // We're using interceptor to inject the requests with the idToken from amplify
    this.apiclient.interceptors.request.use(async (config) => {
      // if (config === process.env.REACT_APP_BASE_URL) {
      //   // check base URL, to prevent sending credentials to third party url
      // }
      if (Auth.user) {
        try {
          const currentSession = await Auth.currentSession();
          config.headers.Authorization = `${currentSession.idToken.jwtToken}`;
          return config;
        } catch (error) {
          console.error('axios error', {error});
          return Promise.reject(error);
        }
      }
    });
  }
}

export default PopCall;

// NOTE Another approach, I'm keeping these in case something bad happend with the class
// const apiclient = axios.create({
//   baseURL: 'http://localhost:8080/admin',
//   timeout: 20000, // 20 seconds to timeout, please adjust accordingly
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   // headers: {
//   //   'Content-Type': 'application/json',
//   //   Authorization:
//   //     'eyJraWQiOiJEck1nMHM1dW43K3BmblZjOVFyejBjSHJSdmZPRzk0UEZVTHZrUkVxNFpBPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmNmRmMGYxOC1hNDJiLTQ4NzgtYTU5ZC00MjU5ODcwZjlmZDAiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX24wekx1TlZ1SCIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6ImFkbWluQHBvcHVsaXguY28iLCJhdWQiOiIzbDBjMmpraGhiaXE0c2VoNWpuN2tzbXVwZyIsImV2ZW50X2lkIjoiNmFkNDA1NGEtN2I0Ny00YzYxLWIwOTUtMWJiM2E2MzA2MmY1IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE1NjMyODE1NzIsIm5hbWUiOiJhZG1pbiIsInBob25lX251bWJlciI6Iis2MjgxMjg4MTQ0ODk1IiwiZXhwIjoxNTYzMjg1MTcyLCJpYXQiOjE1NjMyODE1NzIsImZhbWlseV9uYW1lIjoicG9wdWxpeCIsImVtYWlsIjoiYWRtaW5AcG9wdWxpeC5jbyJ9.b1y_FzcGQMMmup54R_70Ke9vSvv-kVV5f3akyXnPqsb2SXbBnJld-xjg1D6IQgWsYLOlLfnbnMNDyUDfNGG_ROhuGMhIX-OrpN1b2HYKeu5bKb90WnE5cZi64PcOJe2xGj1l08aYP7iCkC097FNUTxstl4gInq2hk7YdFNIioUNOYuQMDkdW1vniouDjYBYtMDdC0eOByduOWi_xuPeGds_VXXLa4ZHGku9f5LKJj6d209cOv8zViqnm_3VcHQT_34HnIz4CpjDHlV_srHTK4iAoFftHmONGKy7qwk5Kg2vFxjYvOjODTa489DAOCGTenJ_yviki-dzjLXjwVXTIJA',
//   // },
// });
// apiclient.interceptors.request.use(async (config) => {
//   // if (config === process.env.REACT_APP_BASE_URL) {
//   //   // check base URL, to prevent sending credentials to third party url
//   // }
//   if (Auth.user) {
//     try {
//       const currentSession = await Auth.currentSession();
//       config.headers.Authorization = `${currentSession.idToken.jwtToken}`;
//       return config;
//     } catch (error) {
//       console.error('axios error', {error});
//       return Promise.reject(error);
//     }
//   }
// });

// export default apiclient;
