import {API} from 'aws-amplify';
import {RESEARCHER_API as researcherAPI} from '../../globals/amplify';

const GET_STUDY_CRITERIONS = 'GET_STUDY_CRITERIONS';
const GET_STUDY_CRITERIONS_SUCCESS = 'GET_STUDY_CRITERIONS_SUCCESS';
const GET_STUDY_CRITERIONS_FAILED = 'GET_STUDY_CRITERIONS_FAILED';

const GET_INFO = 'GET_INFO';
const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS';
const GET_INFO_FAILED = 'GET_INFO_FAILED';

const SELECT_STUDY_CRITERIONS = 'SELECT_STUDY_CRITERIONS';
const SELECT_INFO = 'SELECT_INFO';

const initialState = {
  loadingCriterion: false,
  loadingInfo: false,
  errorCriterion: null,
  errorInfo: null,
  dataCriterion: [],
  dataInfo: [],
  selectedCriterion: {},
  selectedInfo: {},
};

export default function reducers(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_STUDY_CRITERIONS:
      return {...state, loadingCriterion: true};
    case GET_STUDY_CRITERIONS_SUCCESS:
      return {...state, loadingCriterion: false, dataCriterion: payload};
    case GET_STUDY_CRITERIONS_FAILED:
      return {...state, loadingCriterion: false, errorCriterion: payload};

    case GET_INFO:
      return {...state, loadingInfo: true};
    case GET_INFO_SUCCESS:
      return {...state, loadingInfo: false, dataInfo: payload};
    case GET_INFO_FAILED:
      return {...state, loadingInfo: false, errorInfo: payload};

    case SELECT_STUDY_CRITERIONS:
      return {...state, selectedCriterion: payload};
    case SELECT_INFO:
      return {...state, selectedInfo: payload};
    default:
      return state;
  }
}

export const getCriterions = (username) => {
  return (dispatch) => {
    dispatch({type: GET_STUDY_CRITERIONS});
    API.get(researcherAPI, '/researchers/admin/criterions')
      .then((res) => {
        const {data} = res;
        dispatch({type: GET_STUDY_CRITERIONS_SUCCESS, payload: data});
      })
      .catch((error) => {
        dispatch({type: GET_STUDY_CRITERIONS_FAILED, payload: error});
      });
  };
};

export const getInfo = (username) => {
  return (dispatch) => {
    dispatch({type: GET_INFO});
    API.get(researcherAPI, '/researchers/admin/criterions/info')
      .then((res) => {
        const {data} = res;
        dispatch({type: GET_INFO_SUCCESS, payload: data});
      })
      .catch((error) => {
        dispatch({type: GET_INFO_FAILED, payload: error});
      });
  };
};
