import {combineReducers} from 'redux';

import adminStudies from './adminStudies';
import studyCriterions from './studyCriterions';
import auth from './authReducers';

const reducers = combineReducers({
  adminStudies,
  studyCriterions,
  auth,
});

export default reducers;
