import { API } from 'aws-amplify';
import { RESEARCHER_API as researcherAPI } from '../../globals/amplify';
import PopCall from '../../globals/axios';

const popCall = new PopCall();

const GET_ADMIN_STUDIES = 'GET_ADMIN_STUDIES';
const GET_ADMIN_STUDIES_SUCCESS = 'GET_ADMIN_STUDIES_SUCCESS';
const GET_ADMIN_STUDIES_FAILED = 'GET_ADMIN_STUDIES_FAILED';

const GET_ADMIN_STUDY = 'GET_ADMIN_STUDY';
const GET_ADMIN_STUDY_SUCCESS = 'GET_ADMIN_STUDY_SUCCESS';
const GET_ADMIN_STUDY_FAILED = 'GET_ADMIN_STUDY_FAILED';

const CREATE_ADMIN_STUDY = 'CREATE_ADMIN_STUDY';
const CREATE_ADMIN_STUDY_SUCCESS = 'CREATE_ADMIN_STUDY_SUCCESS';
const CREATE_ADMIN_STUDY_FAILED = 'CREATE_ADMIN_STUDY_FAILED';

const UPDATE_ADMIN_STUDY = 'UPDATE_ADMIN_STUDY';
const UPDATE_ADMIN_STUDY_SUCCESS = 'UPDATE_ADMIN_STUDY_SUCCESS';
const UPDATE_ADMIN_STUDY_FAILED = 'UPDATE_ADMIN_STUDY_FAILED';

const DELETE_ADMIN_STUDY = 'DELETE_ADMIN_STUDY';
const DELETE_ADMIN_STUDY_SUCCESS = 'DELETE_ADMIN_STUDY_SUCCESS';
const DELETE_ADMIN_STUDY_FAILED = 'DELETE_ADMIN_STUDY_FAILED';

const GET_SUBMISSION = 'GET_SUBMISSION';
const GET_SUBMISSION_SUCCESS = 'GET_SUBMISSION_SUCCESS';
const GET_SUBMISSION_FAILED = 'GET_SUBMISSION_FAILED';

const CHANGE_SUBMISSION_STATUS = 'CHANGE_SUBMISSION_STATUS';
const CHANGE_SUBMISSION_STATUS_SUCCESS = 'CHANGE_SUBMISSION_STATUS_SUCCESS';
const CHANGE_SUBMISSION_STATUS_FAILED = 'CHANGE_SUBMISSION_STATUS_FAILED';

const ACTIVATE_STUDY = 'ACTIVATE_STUDY';
const ACTIVATE_STUDY_SUCCESS = 'ACTIVATE_STUDY_SUCCESS';
const ACTIVATE_STUDY_FAILED = 'ACTIVATE_STUDY_FAILED';

const initialState = {
  loading: false,
  error: null,
  data: [],
  returnData: null,
  posting: false,
  singleStudyData: {},
  submissionListData: [],
  errorPosting: null,
};

export default function reducers(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADMIN_STUDIES:
      return { ...state, loading: true };
    case GET_ADMIN_STUDIES_SUCCESS:
      return { ...state, loading: false, data: payload, error: null };
    case GET_ADMIN_STUDIES_FAILED:
      return { ...state, loading: false, error: payload };

    case GET_ADMIN_STUDY:
      return { ...state, loading: true };
    case GET_ADMIN_STUDY_SUCCESS:
      return { ...state, loading: false, singleStudyData: payload, error: null };
    case GET_ADMIN_STUDY_FAILED:
      return { ...state, loading: false, error: payload };

    case CREATE_ADMIN_STUDY:
      return { ...state, posting: true };
    case CREATE_ADMIN_STUDY_SUCCESS:
      return { ...state, posting: false, returnData: payload, error: null };
    case CREATE_ADMIN_STUDY_FAILED:
      return { ...state, posting: false, errorPosting: payload };

    case UPDATE_ADMIN_STUDY:
      return { ...state, posting: true };
    case UPDATE_ADMIN_STUDY_SUCCESS:
      return { ...state, posting: false, returnData: payload, error: null };
    case UPDATE_ADMIN_STUDY_FAILED:
      return { ...state, posting: false, error: payload };

    case DELETE_ADMIN_STUDY:
      return { ...state, loading: true };
    case DELETE_ADMIN_STUDY_SUCCESS:
      return { ...state, loading: false, returnData: payload, error: null };
    case DELETE_ADMIN_STUDY_FAILED:
      return { ...state, loading: false, error: payload };

    case GET_SUBMISSION:
      return { ...state, loading: true };
    case GET_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        submissionListData: payload,
        error: null,
      };
    case GET_SUBMISSION_FAILED:
      return { ...state, loading: false, error: payload };

    case ACTIVATE_STUDY:
      return { ...state, posting: true };
    case ACTIVATE_STUDY_SUCCESS:
      return { ...state, posting: false, returnData: payload, error: null };
    case ACTIVATE_STUDY_FAILED:
      return { ...state, posting: false, error: payload };

    default:
      return state;
  }
}

export const getStudies = (payload) => {
  return (dispatch) => {
    dispatch({ type: GET_ADMIN_STUDIES_SUCCESS, payload });
  };
};

export const getStudy = (username, id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: GET_ADMIN_STUDY });
      API.get(researcherAPI, `/researchers/${username}/studies/${id}`)
        .then((res) => {
          const { data } = res;
          dispatch({ type: GET_ADMIN_STUDY_SUCCESS, payload: data });
          resolve(data);
        })
        .catch((error) => {
          dispatch({ type: GET_ADMIN_STUDY_FAILED, payload: error });
          reject();
        });
    });
  };
};

export const updateStudy = (formData, history, id, username, is_draft) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_ADMIN_STUDY });
    API.put(researcherAPI, `/researchers/${username}/studies/${id}`, {
      body: { ...formData, is_draft, status: is_draft ? 'Draft' : 'Aktif' },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        const { data } = res;
        dispatch({ type: UPDATE_ADMIN_STUDY_SUCCESS, payload: data });
      })
      .catch((error) => {
        dispatch({ type: UPDATE_ADMIN_STUDY_FAILED, payload: error });
      });
  };
};

export const activateStudy = (username, id) => {
  return (dispatch) => {
    dispatch({ type: ACTIVATE_STUDY });
    API.post(researcherAPI, `/researchers/${username}/studies/${id}/activate`, {
      body: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        dispatch({ type: ACTIVATE_STUDY_SUCCESS, payload: res });
      })
      .catch((error) => {
        dispatch({ type: ACTIVATE_STUDY_FAILED, payload: error });
      });
  };
};
// NOTE Not on Spec
export const deleteStudy = (id, username) => {
  return (dispatch) => {
    dispatch({ type: DELETE_ADMIN_STUDY });
    API.del(researcherAPI, `/researchers/test_user_dummy/studies/${id}`)
      .then((res) => {
        dispatch({ type: DELETE_ADMIN_STUDY_SUCCESS, payload: res });
      })
      .catch((error) => {
        dispatch({ type: DELETE_ADMIN_STUDY_FAILED, payload: error });
      });
  };
};

export const getSubmission = (id, username) => {
  return (dispatch) => {
    dispatch({ type: GET_SUBMISSION });
    // TODO change 99  with ID;
    popCall
      .get(`admin/studies/${id}/submissions`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: GET_SUBMISSION_SUCCESS, payload: data });
      })
      .catch((error) => {
        dispatch({ type: GET_SUBMISSION_FAILED, payload: error });
      });
  };
};

export const changeSubmissionStatus = (
  // username,
  study_id,
  submission_id,
  dataToSubmit,
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: CHANGE_SUBMISSION_STATUS });
      popCall
        .patch(`/admin/studies/${study_id}/submissions/${submission_id}`, dataToSubmit)
        .then((res) => {
          const { data } = res;
          dispatch({ type: CHANGE_SUBMISSION_STATUS_SUCCESS, payload: data });
          resolve(data);
        })
        .catch((error) => {
          dispatch({ type: CHANGE_SUBMISSION_STATUS_FAILED, payload: error });
          reject(error);
        });
    });
  };
};

export const deleteSubmission = (study_id, submission_id) => {
  return new Promise((resolve, reject) => {
    API.del(
      researcherAPI,
      `/admin/studies/${study_id}/submissions/${submission_id}`,
    )
      .then((res) => {
        const { data } = res;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
